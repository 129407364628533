









































































































import { Component, Vue } from "vue-property-decorator";
import LsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue"
import { RequestPaging } from "@/utils/util";
import { apiCouponRecord, apiCouponVoid } from "@/api/marketing/coupon";
import DatePicker from "@/components/date-picker.vue"
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        LsDialog,
        DatePicker,
        ExportData
    },
})
export default class Goods extends Vue {
    /** S Data **/
    $refs!: { paneTable: any }

    apiCouponRecord = apiCouponRecord

    couponSearchData = {
        nickname: "",
        status: "",
        name: "",
        start_time: "",
        end_time: "",
    };

    options = [
        { value: "", label: "全部" },
        { value: "0", label: "未使用" },
        { value: "1", label: "已使用" },
        { value: "2", label: "已过期" },
        { value: "3", label: "已作废" },
    ];

    pager = new RequestPaging();

    selectIds: Array<string> = []

    /** E Data **/

    /** S Method **/

    // 获取优惠券记录数据
    getCouponRecord(): void {
        this.pager.request({
            callback: apiCouponRecord,
            params: {
                ...this.couponSearchData,
            },
        });
    }

    // 选择某条数据
    selectionChange(val: any[]) {
        this.selectIds = val.map((item) => item.cl_id)
    }

    // 全选优惠券
    selectAll() {
        this.$refs.paneTable.toggleAllSelection()
    }

    //  作废优惠券
    couponDel(ids: Array<Number>) {
        if(this.selectIds.length == 0) return this.$message.error('请先选择要作废的优惠券')
        apiCouponVoid({
            cl_id: Array.isArray(ids) ? ids : this.selectIds
        }).then(res => {
            this.resetcouponSearchData()
        })
    }

    // 重置搜索领取记录
    resetcouponSearchData() {
        Object.keys(this.couponSearchData).map((key) => {
            this.$set(this.couponSearchData, key, "");
        });
        this.getCouponRecord()
    }

    // 去订单详情
    toOrder(id: any) {
        this.$router.push({
            path: "/order/order_detail",
            query: { id: id },
        });
    }

    // 去用户详情
    toUser(id: any) {
        this.$router.push({
            path: "/user/user_details",
            query: { id: id },
        });
    }

    /** E Method **/

    created() {
        this.getCouponRecord();
    }
}
